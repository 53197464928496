import React, { useContext, useState, useEffect } from "react";
import { Navigation } from "../utility/utility";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { PatientpageContext } from "../context/GlobalContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import Skeleton from "@mui/material/Skeleton";
import { Ls } from "../utility/utility";
import Button from "../Tools/Button";
import { OverlayContext } from "../OverlayManager/Overlay";
import CircularProgress from "@mui/material/CircularProgress";
import Gap from "../Tools/Gap";
import { OverlayType } from "../OverlayManager/Context";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Swtich from "../Tools/Swtich";

const Order = () => {
  const { mode } = useParams();
  sessionStorage.setItem('mode', mode);

  const appointmentID = atob(mode);
  const [loader, setLoader] = useState(false);
  const Overlay = useContext(OverlayContext);
  const [orderDetails, setOrderDetails] = useState("");
  const orderId = orderDetails?.order_id;
  sessionStorage.setItem('orderId', orderId);
const balance = orderDetails?.balance_due;
sessionStorage.setItem('balance', balance)
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const OrderSummary = {
    "Total Fee": `${orderDetails?.currency} ${orderDetails?.total_fee}`,
    "Total Discounts": `(${orderDetails?.currency} ${
      orderDetails?.total_discount || 0
    })`,
    "GST Applicable": `${orderDetails?.currency} ${
      orderDetails?.gst_applicable || 0
    }`
  };

  const paymentOptions = {
    // "Confirm Cash Payment":
    //   orderDetails?.show_confirm_cash_payment_button || false,
      // "Partial Payement" :
      // orderDetails?.partial_payement_button || true,
          // "Send Payment Link": orderDetails?.show_send_payment_link_button || false,
    // "Check Payment Status":
    //   orderDetails?.show_check_payment_status_button || false,
    // "Cancel Payment Link":
    //   orderDetails?.show_cancel_payment_link_button || false,
    "Cancel Order": orderDetails?.show_cancel_order_button || true,
  };

  useEffect(() => {
    handleOrderDetails();
  }, []);

  const PaymentPopup = ({ handleOrderDetails }) => {
    const [fetchLoader, setFetchLoader] = useState(false);
    const [fetchDefaultData, setFetchDefaultData] = useState("");
    const [createPaymentlink, setCreatePaymentLink] = useState("");
    const phoneRegExp = /^[6-9]\d{9}$/;
    useEffect(() => {
      handleFetchDefaultValue();
    }, []);
    const handleFetchDefaultValue = async () => {
      const { data, error } = await doctorContext?.getv2Call(
        "/v2/doctor/fetch/pay/link/defaults/",
        appointmentID,
        setFetchLoader
      );

      if (data) {
        setFetchDefaultData(data?.data);
      }
    };

    const handleCreatePaymentLink = async (values, error) => {
      if (error && !Object.keys(error).length) {
        const options = { ...values, phone: `+91${values?.phone}` };
        const { data, error } = await doctorContext?.postPutV2Call(
          "/v2/doctor/service/order/payment/link/",
          appointmentID,
          options,
          setFetchLoader
        );
        if (data) {
          setCreatePaymentLink(data?.data?.message);
        }
      }
    };

    if (!createPaymentlink) {
      return (
        <div className="raiseOrder_popup">
          {fetchLoader ? (
            <div className="addServices__raiseOrder_loader raiseOrder_popup_loader">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <></>
          )}
          <div className="raiseOrder_popup_heading">Send Payment Link</div>
          <Gap size="1rem" />
          <div className="raiseOrder_popup_text">
            {fetchDefaultData?.message || ""}
          </div>
          <div className="raiseOrder_popup_form">
            <Formik
              enableReinitialize
              validateOnMount
              initialTouched={{ zip: true }}
              initialValues={{
                phone: fetchDefaultData?.phone || "",
                email: fetchDefaultData?.email || "",
                send_sms: fetchDefaultData?.send_sms || false,
                send_email: fetchDefaultData?.send_email || false,
                send_wa: fetchDefaultData?.send_wa || false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email("Invalid Email").required("Required"),
                phone: Yup.string()
                  .matches(phoneRegExp, "Phone number is not valid")
                  .required("Required"),
              })}
              onSubmit={() => {
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => {
                return (
                  <div className="raiseOrder_popup_form_container">
                    <div className="d-flex flex-column align-items-end">
                      <div className="raiseOrder_popup_form_phone">
                        <span>{fetchDefaultData?.country_code || "+91"}</span>
                        <Field
                          className="addServicesModal__form_input"
                          type="number"
                          name="phone"
                          placeholder="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <ErrorMessage
                        style={{ maxWidth: "100%" }}
                        className="addServicesModal__form_error"
                        component="div"
                        name="phone"
                      />
                    </div>
                    <div className="raiseOrder_popup_form_email">
                      <Field
                        type="text"
                        className="addServicesModal__form_input"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        className="addServicesModal__form_error"
                        component="div"
                        name="email"
                      />
                    </div>
                    <div className="raiseOrder_popup_form_switch">
                      <div>
                        <SmsOutlinedIcon
                          sx={{ fontSize: "2rem", color: "#aeb9cc" }}
                        />
                        <Swtich
                          checked={values?.send_sms}
                          ContainerClass="mobileTimerPicker__switch"
                          handleChange={handleChange}
                          props={{
                            name: "send_sms",
                          }}
                        />
                      </div>
                      <div>
                        <MailOutlineIcon
                          sx={{ fontSize: "2rem", color: " #4285F4" }}
                        />
                        <Swtich
                          checked={values?.send_email}
                          ContainerClass="mobileTimerPicker__switch"
                          handleChange={handleChange}
                          props={{
                            name: "send_email",
                          }}
                        />
                      </div>
                      <div>
                        <WhatsAppIcon
                          sx={{ fontSize: "2rem", color: "#075e54" }}
                        />
                        <Swtich
                          checked={values?.send_wa}
                          ContainerClass="mobileTimerPicker__switch"
                          handleChange={handleChange}
                          props={{
                            name: "send_wa",
                          }}
                        />
                      </div>
                    </div>
                    <div className="raiseOrder_popup_form_button">
                      <button
                        onClick={() => {
                          handleSubmit();
                          handleCreatePaymentLink(values, errors);
                        }}
                        type="button"
                        className="static_button addServicesModal__form__button_save"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      );
    }
    return (
      <div className="raiseOrder_popup raiseOrder_popup_payment">
        {" "}
        <div className="raiseOrder_popup_heading">{createPaymentlink}</div>
        <Gap size="1rem" />
        <button
          onClick={() => {
            Overlay.hide();
            handleOrderDetails();
          }}
          type="button"
          className="static_button addServicesModal__form__button_save"
        >
          Ok
        </button>
      </div>
    );
  };

  const CheckPaymentStatus = () => {
    const [paymentStatus, setPaymentStatus] = useState("");
    const [popupLoader, setPopupLoader] = useState(false);
    useEffect(() => {
      handlePaymentStatus();
    }, []);
    const handlePaymentStatus = async () => {
      const { data, error } = await doctorContext?.getv2Call(
        "/v2/doctor/service/order/fetch/payment/link/",
        appointmentID,
        setPopupLoader
      );

      if (data) {
        setPaymentStatus(data?.data);
      }
    };

    const isSuccess = paymentStatus?.status === "paid";
    return (
      <div className="paymentStatus__popup">
        {popupLoader ? (
          <>
            {" "}
            <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
            <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
            <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
          </>
        ) : (
          <>
            {paymentStatus?.short_url ? (
              <a href={paymentStatus?.short_url} target="_blank">
                {paymentStatus?.short_url}
              </a>
            ) : (
              <></>
            )}
            <div className="paymentStatus__popup_icon">
              {isSuccess ? (
                <VerifiedIcon sx={{ fontSize: "3.5rem", color: "#00b050" }} />
              ) : (
                <HourglassEmptyIcon
                  sx={{ fontSize: "3.5rem", color: "#0070c0" }}
                />
              )}
            </div>
            <div className="paymentStatus__popup_text">
              {paymentStatus?.message || ""}
            </div>
            <div className="paymentStatus__popup_button">
              <button
                onClick={() => {
                  Overlay.hide();
                  isSuccess &&
                    history.push({
                      pathname: "/add-services",
                      search: history?.location?.search,
                    });
                }}
                type="button"
                className="static_button addServicesModal__form__button_save"
              >
                Ok
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const CancelOrder = () => {
    const [cancelLoader, setCancelLoader] = useState(false);
    const [cancelDetails, setCancelDetails] = useState("");
    const handleCancelServiceOrder = async () => {
      const { data, error } = await doctorContext?.deleteV2Call(
        "/v2/doctor/cancel/service/order/",
        appointmentID,
        setCancelLoader
      );
      if (data) {
        setCancelDetails(data?.message);
      }
    };
    if (!cancelDetails) {
      return (
        <div className="addServices__cancelOrder">
          {cancelLoader ? (
            <div className="addServices__raiseOrder_loader">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <></>
          )}
          <p className="addServices__cancelOrder_text">
            Are you sure you would like to cancel the order?
          </p>
          <div className="addServices__cancelOrder_buttons">
            <Button
              text="Back"
              handleClick={() => {
                Overlay.hide();
              }}
            />
            <Button text="Go Ahead" handleClick={handleCancelServiceOrder} />
          </div>
        </div>
      );
    }

    return (
      <div className="addServices__cancelOrder">
        <div className="raiseOrder_popup_heading">{cancelDetails}</div>
        <Gap size="1rem" />
        <button
          onClick={() => {
            Overlay.hide();
            history.push({
              pathname: "/add-services",
              search: history?.location?.search,
            });
          }}
          type="button"
          className="static_button addServicesModal__form__button_save"
        >
          Ok
        </button>
      </div>
    );
  };

  const CancelPayment = ({ handleOrderDetails }) => {
    const [paymentCancel, setPaymentCancel] = useState("");
    const [popupLoader, setPopupLoader] = useState(false);
    useEffect(() => {
      handleCancelOrder();
    }, []);
    const handleCancelOrder = async () => {
      const { data, error } = await doctorContext?.deleteV2Call(
        "/v2/doctor/service/order/cancel/payment/link/",
        appointmentID,
        setPopupLoader
      );

      if (data) {
        setPaymentCancel(data?.data?.message);
      }
    };

    return (
      <div className="paymentStatus__popup paymentStatus__popup_cancel">
        {popupLoader ? (
          <>
            {" "}
            <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
            <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
          </>
        ) : (
          <>
            <LinkOffIcon sx={{ fontSize: "3.5rem", color: "#0070c0" }} />
            <div>{paymentCancel || <> Payment link cancelled </>}</div>
            <button
              onClick={() => {
                Overlay.hide();
                handleOrderDetails();
              }}
              type="button"
              className="static_button addServicesModal__form__button_save"
            >
              Ok
            </button>
          </>
        )}
      </div>
    );
  };

  const CashOrder = () => {
    const [popupLoader, setPopupLoader] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState("");

    useEffect(() => {
      hanleCashOrder();
    }, []);

    const hanleCashOrder = async () => {
      const { data, error } = await doctorContext?.postPutV2Call(
        "/v2/doctor/confirm/pay/cash/order/",
        `${appointmentID}/${orderDetails?.order_id}`,
        "",
        setPopupLoader
      );

      if (data?.data) {
        setPaymentStatus(data?.data);
      }
    };

    return (
      <div className="paymentStatus__popup">
        {popupLoader ? (
          <CircularProgress
            color="inherit"
            sx={{ fontSize: "3.5rem", color: "#000000" }}
          />
        ) : (
          <>
            <div className="paymentStatus__popup_icon">
              <VerifiedIcon sx={{ fontSize: "3.5rem", color: "#00b050" }} />
            </div>
            <div className="paymentStatus__popup_text">
              {paymentStatus?.message || ""}
            </div>
            <div className="paymentStatus__popup_button">
              <button
                onClick={() => {
                  Overlay.hide();
                  history.push({
                    pathname: "/add-services",
                    search: history?.location?.search,
                  });
                }}
                type="button"
                className="static_button addServicesModal__form__button_save"
              >
                Ok
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleClick = () => {
    history.push({
      pathname: "/add-services",
      search: history?.location?.search,
    });
  };

  const handleOrderDetails = async () => {
    const { data, error } = await doctorContext?.getv2Call(
      "/v2/doctor/fetch/service/order/details/",
      appointmentID,
      setLoader
    );
    if (data?.data) {
      setOrderDetails(data?.data);
    }
  };

const handlePaymens = () =>{
  history.push({
    pathname: `/order/payement`,
    // search: history.location.search,
    state: { appointmentId: appointmentID }

  });

}

  const handlePayment = (item) => {
    console.log("clicking")
    switch (item) {
      case "Confirm Cash Payment":
        (() => {
          Overlay.show(OverlayType?.ModalWindow, {
            data: <CashOrder handleOrderDetails={handleOrderDetails} />,
            removeCloseButton: true,
            handleClose: () => {},
          });
        })();
        break;
      case "Send Payment Link":
        (() => {
          Overlay.show(OverlayType?.ModalWindow, {
            data: <PaymentPopup handleOrderDetails={handleOrderDetails} />,
            removeCloseButton: false,
            handleClose: () => {},
          });
        })();
        break;
      case "Check Payment Status":
        (() => {
          Overlay.show(OverlayType?.ModalWindow, {
            data: <CheckPaymentStatus />,
            removeCloseButton: true,
            handleClose: () => {},
          });
        })();
        break;
      case "Cancel Payment Link":
        (() => {
          Overlay.show(OverlayType?.ModalWindow, {
            data: <CancelPayment handleOrderDetails={handleOrderDetails} />,
            removeCloseButton: false,
            handleClose: () => {},
          });
        })();
        break;
      case "Cancel Order":
        Overlay.show(OverlayType?.ModalWindow, {
          data: <CancelOrder />,
          removeCloseButton: true,
          handleClose: () => {},
        });
    }
  };
const rupee = "\u20B9"
  return (
    <div className="raiseOrder">
      <Navigation
        text="Order"
        // close={true}
        navClass="appointmentpage__navigation"
        handleClick={handleClick}
      />
      <div className="raiseOrder__reload">
        <RefreshIcon onClick={handleOrderDetails} />
      </div>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <h1 style={{fontSize: "16px"}}>{orderDetails?.order_id || ""}</h1>
        <span style={{fontWeight: "bold"}}>Balance Due: {rupee}{orderDetails?.balance_due}</span>
      </div>
      <div className="raiseOrder__order">
        <div className="raiseOrder__order_heading">Order Details</div>
        <div className="raiseOrder__order__container">
          <div className="raiseOrder__order__container_details">
            <span>Particulars</span>
            <span>Amount</span>
          </div>{" "}
          {loader ? (
            <>
              <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
              <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
              <Skeleton sx={{ width: "100%", fontSize: "3rem" }} />
            </>
          ) : (
            <>
              {Object.keys(OrderSummary)?.map((item) => (
                <div className="raiseOrder__order__container_list">
                  <span>{item}</span>
                  <span
                    className={
                      item === "Total Discounts"
                        ? "raiseOrder__order__container_list_discount"
                        : ""
                    }
                  >
                    {OrderSummary[item]}
                  </span>
                </div>
              ))}
            </>
          )}
          <div className="raiseOrder__order__container_total">
            <span>Total Amount</span>
            <span>
              {loader ? (
                <Skeleton sx={{ width: "20%", fontSize: "3rem" }} />
              ) : (
                <>Rs. {orderDetails?.payable_amount}</>
              )}
            </span>
          </div>
        </div>
        <p className="raiseOrder__order_status">
          Status: <span>{orderDetails?.order_status || ""}</span>
        </p>
        {orderDetails?.order_status !== "PAID" ?
        <p className="raiseOrder__order_confirm">
          Click “Confirm” to confirm the receipt of cash payment.
        </p>:
        <></>
        }
        <Gap size="1rem" />
        <div className="raiseOrder__order_buttons">
        <Button
                    text={'Payement'}
                    handleClick={handlePaymens} 
                    // loader={!paymentOptions[item]}
                  />
          {loader ? (
            <>
              <Skeleton sx={{ width: "80%", fontSize: "3rem" }} />
              <Skeleton sx={{ width: "80%", fontSize: "3rem" }} />
              <Skeleton sx={{ width: "80%", fontSize: "3rem" }} />
            </>
          ) : (
            <>
              {Object.keys(paymentOptions)
                ?.filter((item) => paymentOptions[item])
                ?.map((item) => (
                  <Button
                    text={item}
                    handleClick={() => {
                      handlePayment(item);
                    }}
                    loader={!paymentOptions[item]}
                  />
                ))}
            </>
          )}
           
        </div>
      </div>
    </div>
  );
};

export default Order;

import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Navigation } from "../utility/utility";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../Tools/Button";
import { OverlayContext } from "../OverlayManager/Overlay";
import { OverlayType } from "../OverlayManager/Context";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { PatientpageContext } from "../context/GlobalContext";
const Partialpayement = () => {
  const history = useHistory("");
  const Overlay = useContext(OverlayContext);
  const location = useLocation("");
  const appointmentId = location.state?.appointmentId;
  const [serviceData, setServiceData] = useState([]);
  const storedOrderId = sessionStorage.getItem('orderId');
  const balance = sessionStorage.getItem('balance');
  const mode = sessionStorage.getItem('mode');
  console.log(224455, mode)

  const doctorContext = useContext(PatientpageContext)
  const setState = doctorContext.setState;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

    const fetchpayement = async () => {
      try {
        const payement = await ServiceCall.getv2(
          "v2/doctor/fetch/service/order/transactions/",
          `${appointmentId}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (payement) {
          setServiceData(payement?.data);
        }
      } catch (err) {}
    };


    useEffect(() =>{
    fetchpayement();
  }, []);

  const PaymentPopup = ({ handleOrderDetails, appointmentId , onClose, storedOrderId, fetchpayement}) => {
    const [createPaymentlink, setCreatePaymentLink] = useState("");

    const paymentMethods = [
      { value: "cash", label: "Cash" },
      { value: "card", label: "Card" },
      { value: "upi", label: "UPI" },
    ];

    const validationSchema = Yup.object().shape({
      paymentMethod: Yup.string().required("Payment method is required"),
      amount: Yup.number()
        .positive("Amount must be positive")
        .required("Amount is required"),
      reference: Yup.string().required("Reference is required"),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
      try {
        const body = {
          order_id: storedOrderId,
          payment_mode: values.paymentMethod,
          currency: "INR",
          transaction_amount: values.amount,
          transaction_notes: values.reference,
        };

        const paymentput = await ServiceCall.putv2(
          "v2/doctor/capture/service/order/transactions/",
          `${appointmentId}`,
          body,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if(paymentput){
          fetchpayement();
          onClose();
          setState({
            msg: paymentput?.data.message || "",
            type: "success",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }
      } catch (err) {
        console.error("Error processing payment:", err);
      } finally {
        setSubmitting(false);
      }
    };

    if (!createPaymentlink) {
      return (
        <div className="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
          <h2 style={{ textAlign: "center" }}>Process Payment</h2>
          <Formik
            initialValues={{ paymentMethod: "", amount: "", reference: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Field
                    as="select"
                    name="paymentMethod"
                    placeholder="Payment Method"
                    style={{
                      border: "1px solid #e7e7e7",
                      borderRadius: "8px",
                      padding: ".5rem 1rem",
                      WebkitAppearance: "none",
                    }}
                  >
                    <option value="">Select a payment method</option>
                    {paymentMethods.map((method) => (
                      <option key={method.value} value={method.value}>
                        {method.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="paymentMethod"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                    style={{ color: "red", fontSize: "13px" }}
                  />
                </div>

                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <span
                    className="mr-2 font-semibold"
                    style={{
                      marginLeft: "-2rem",
                      border: "1px solid #e7e7e7",
                      borderRadius: "8px",
                      padding: ".5rem",
                    }}
                  >
                    INR
                  </span>
                  <Field
                    type="number"
                    name="amount"
                    placeholder="amount paid"
                    style={{
                      border: "1px solid #e7e7e7",
                      borderRadius: "8px",
                      padding: ".5rem 1rem",
                      WebkitAppearance: "none",
                    }}
                  />
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                    style={{ color: "red", fontSize: "13px" }}
                  />
                </div>

                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Field
                    type="text"
                    name="reference"
                    placeholder="notes (optional)"
                    style={{
                      border: "1px solid #e7e7e7",
                      borderRadius: "8px",
                      padding: ".5rem 1rem",
                      WebkitAppearance: "none",
                    }}
                  />
                  <ErrorMessage
                    name="reference"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                    style={{ color: "red", fontSize: "13px" }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "150%",
                      borderRadius: "8px",
                      padding: "0.3rem 1rem",
                      width: "25%",
                      background: "#199a8e",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {isSubmitting ? "Processing..." : "Confirm"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      );
    }
  };
  const handleNewButton = () => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <PaymentPopup
          appointmentId={appointmentId}
          onClose={() => Overlay.hide()}
          storedOrderId={storedOrderId}
          fetchpayement={fetchpayement}
        />
      ),
      removeCloseButton: false,
      handleClose: () => {},
    });
  };
  const handleClick = () => {
    history.push({
      pathname: `/add-services/order/${mode}`,
      search: history?.location?.search,
    });
  };

  const rupee = "\u20B9"


  return (
    <div className="addServices">
      <Navigation
        text="Payement"
        // close={true}
        navClass="appointmentpage__navigation"
        handleClick={handleClick}
      />
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <h1 style={{fontSize: "16px"}}>{storedOrderId || ""}</h1>
        <span style={{fontWeight: "bold"}}>Balance Due: {rupee}{balance}</span>
      </div>
      <div
        className="addServices__new"
        style={{ marginBottom: "1rem", display: "flex" }}
      >
        <Button text="Payement +" handleClick={handleNewButton} />
        <p style={{ fontSize: "15px", marginLeft: "1rem" }}>
          Click to start capturing payment.
        </p>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ textAlign: "center" }}>
                Transaction ID
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Date
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Amount
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Mode
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Balance
              </StyledTableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceData?.transcations &&
              Array.isArray(serviceData?.transcations) &&
              !!serviceData?.transcations?.length &&
              serviceData?.transcations.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.transaction_id || ""}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {row?.date || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.amount || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.mode || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.balance_due || ""}
                  </StyledTableCell>
                
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Partialpayement;

import React, { useContext, useEffect, useState } from "react";
import { Navigation } from "../utility/utility";
import { ServiceCall } from "../utility/service-call";
import { PatientpageContext } from "../context/GlobalContext";
import { Ls } from "../utility/utility";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddServicesModal from "./AddServicesModal";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { OverlayContext } from "../OverlayManager/Overlay";
import Button from "../Tools/Button";
import { OverlayType } from "../OverlayManager/Context";
import { useHistory } from "react-router-dom";
import PageContentLayout from "./PageContentLayout";

const AddServices = (props) => {
  const doctorContext = useContext(PatientpageContext);
  const Overlay = useContext(OverlayContext);
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
    console.log(2233445, appointment)
  const [serviceData, setServiceData] = useState([]);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [raiseLoader, setRaiseLoader] = useState(false);
  const [messageWindow, setMessageWindow] = useState("");
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    getServices();
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


  const handleRaiseOrder = async () => {
    const { data, error } = await doctorContext?.postPutV2Call(
      "/v2/doctor/generate/order/",
      `${appointment?.appointmentItem?.appointment_id}/cash`,
      "",
      setRaiseLoader
    );
    if (data) {
      setMessageWindow(data?.data?.message);
      setShowButton(true); // Show the button when we get a response
    }
  };

  const handleOkClick = () => {
    Overlay.hide();
    history.push({
      pathname: `/add-services/order/${btoa(
        appointment?.appointmentItem?.appointment_id
      )}`,
      search: history?.location?.search
    });
  };



  const handleClick = () => {
    history.push({
      pathname: "/my-appointments",
      search: history?.location?.search,
    });
  };
  const handleNewButton = () => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: <AddServicesModal />,
      removeCloseButton: false,
      handleClose: () => {},
      getServices: getServices,
    });
  };

  const getServices = async () => {
    setLoader(true);
    setServiceData([]);
    try {
      const response = await ServiceCall.getv2(
        "/v2/doctor/manage/opd/service/",
        appointment?.appointmentItem?.appointment_id,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (response) {
        setServiceData(response?.data);
        setLoader(false);
      }
    } catch (err) {
      doctorContext?.setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      setLoader(false);
      setServiceData("No Data Found");
    }
  };

  const handleDeleteService = async (row) => {
    setServiceData([]);
    try {
      const { data, error } = await doctorContext?.deleteV2Call(
        "/v2/doctor/delete/opd/service/",
        `${appointment?.appointmentItem?.appointment_id}/${row.id}`,
        setLoader
      );

      if (data?.message) {
        getServices();
      }
    } catch (err) {
      getServices();
    }
  };
  

  return (
    <PageContentLayout>
    <div className="addServices">
      <Navigation
        text="Services"
        // close={true}
        navClass="appointmentpage__navigation"
        handleClick={handleClick}
      />
      <div className="addServices_container">
        <div className="addServices__heading">
          <p>
            Click Add+ to add any other additional services than Consultations
          </p>
          <RefreshIcon onClick={getServices} style={{cursor: "pointer"}} />
        </div>
        <div className="addServices__new-button">
          <Button text="Add +" handleClick={handleNewButton} />
        </div>
        <div className="addServices__table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{textAlign: "center"}}>Services</StyledTableCell>
                  <StyledTableCell  style={{textAlign: "center"}}>Fee</StyledTableCell>
                  <StyledTableCell  style={{textAlign: "center"}}>GST</StyledTableCell>
                  <StyledTableCell  style={{textAlign: "center"}}>Discount</StyledTableCell>
                  <StyledTableCell  style={{textAlign: "center"}}>Total</StyledTableCell>
                  <StyledTableCell  style={{textAlign: "center"}}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceData?.services &&
                  Array.isArray(serviceData?.services) &&
                  !!serviceData?.services?.length &&
                  serviceData?.services.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell  style={{textAlign: "center"}}>
                        {row?.service || ""}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" style={{textAlign: "center"}}>
                        {row?.fee || ""}
                      </StyledTableCell>
                      <StyledTableCell  style={{textAlign: "center"}}>
                        {row?.gst || ""}
                      </StyledTableCell>
                      <StyledTableCell  style={{textAlign: "center"}}>
                        {row?.discount_amount || ""}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "center"}}>
                        {row?.total_amount || ""}
                      </StyledTableCell>
                      <StyledTableCell  style={{textAlign: "center"}}>
                        <RemoveCircleOutlineIcon
                          onClick={() => {
                            handleDeleteService(row);
                          }}
                          sx={{ fontSize: "15px" }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {loader ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (serviceData?.service &&
              Array.isArray(serviceData?.service) &&
              !serviceData?.service.length) ||
            typeof serviceData === "string" ? (
            <p className="addServices__error">No Data Found</p>
          ) : (
            <></>
          )}
        </div>
        <div className="addServices__buttons">
          <Button
            text="Raise Order"
            handleClick={handleRaiseOrder}
            containerClass={`${
              !serviceData?.show_raise_order_button ? "static_disable" : ""
            } addServices__buttons_raise`}
          />
          <Button
            text="Order Details"
            handleClick={() => {
              history.push({
                pathname: `/add-services/order/${btoa(
                  appointment?.appointmentItem?.appointment_id
                )}`,
                search: history?.location?.search,
              });
            }}
            containerClass={`${
              !serviceData?.show_order_details_button ? "static_disable" : ""
            } addServices__buttons_cancel`}
          />
        </div>
      </div>
      {showButton && (
        <div style={{textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center"}}>
         <div className="addServices__raiseOrder" style={{backgroundColor: "#f4eeee"}}>
         <p className="addServices__raiseOrder_message">{messageWindow}</p>
         <Button
           text="Ok"
           containerClass="addServices__raiseOrder_button_message"
           handleClick={() => {
             Overlay.hide();
             history.push({
               pathname: `/add-services/order/${btoa(
                 appointment?.appointmentItem?.appointment_id
               )}`,
               search:history?.location?.search
             });
           }}
         />
       </div>
       </div>
        )}
    </div>
   

    </PageContentLayout>
  );
};

export default AddServices;
